/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { ApiService } from './../../services/apis.service';
import { Component, OnInit } from '@angular/core';
import { ActionSheetController, ModalController, NavController, NavParams } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
@Component({
  selector: 'app-return-order',
  templateUrl: './return-order.page.html',
  styleUrls: ['./return-order.page.scss'],
})
export class ReturnOrderPage implements OnInit {

  images: any[] = [];
  orderId: any = '';
  productId: any = '';
  name: any = '';
  reason: any = '';
  constructor(
    private navCtrl: NavController,
    public api: ApiService,
    private route: ActivatedRoute,
    public util: UtilService,
    private modalCtrl: ModalController,
    private navParam: NavParams,
    private actionSheetController: ActionSheetController) {

    if (this.navParam.get('id') && this.navParam.get('productId')) {
      this.orderId = this.navParam.get('id');
      this.orderId = this.navParam.get('productId');
      this.name = this.navParam.get('name');
    } else {
      this.goToBack();
    }
  }

  ngOnInit() {
  }

  goToBack() {
    this.modalCtrl.dismiss();
  }

  async uploadImage(source: CameraSource) {
    try {
      const image = await Camera.getPhoto({
        source,
        quality: 50,
        resultType: CameraResultType.Base64
      });
      console.log('image output', image);
      if (image && image.base64String) {
        const blobData = this.b64toBlob(image.base64String, `image/${image.format}`);
        this.util.show(this.util.translate('Uploading..'));
        this.api.uploadImage('v1/uploadImage', blobData, image.format).then((data) => {
          console.log('image upload', data);
          this.util.hide();
          if (data && data.status == 200 && data.success == true && data.data.image_name) {
            this.images.push(data.data.image_name);
            // this.cover = data.data.image_name;
            // console.log('this cover', this.cover);
          } else {
            console.log('NO image selected');
          }
        }).catch(error => {
          console.log('error while uploading', error);
          this.util.hide();
          this.util.apiErrorHandler(error);
        });
      }
    } catch (error) {
      console.log(error);
      this.util.apiErrorHandler(error);
    }
  }

  async getImage() {
    const buttons = [
      {
        text: this.util.translate('Take Photo'),
        icon: 'camera',
        handler: () => {
          this.uploadImage(CameraSource.Camera);
        }
      },
      {
        text: this.util.translate('Choose From Photos Photo'),
        icon: 'image',
        handler: () => {
          this.uploadImage(CameraSource.Photos);
        }
      },
      {
        text: this.util.translate('Cancel'),
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }
    ];

    // Only allow file selection inside a browser

    const actionSheet = await this.actionSheetController.create({
      header: this.util.translate('Select Image Source'),
      buttons
    });
    await actionSheet.present();
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  returnItem() {
    if (this.reason == '' || !this.reason) {
      this.util.errorToast('Please write reason for returns');
      return false;
    }
    const param = {
      productId: this.productId,
      orderId: this.orderId,
      uid: localStorage.getItem('uid'),
      reasons: this.reason,
      images: this.images.length ? this.images.join() : 'NA',
      status: 0
    };
    this.util.show();
    this.api.post_private('v1/returns/create', param).then((data: any) => {
      console.log(data);
      this.util.hide();
      if (data && data.status && data.status == 200) {
        this.util.showToast('Your return request is submitted successfully, we will take look into it and update you on same', 'success', 'bottom');
        this.goToBack();
      }
    }, error => {
      console.log(error);
      this.util.hide();
      this.util.apiErrorHandler(error);
    }).catch(error => {
      console.log(error);
      this.util.hide();
      this.util.apiErrorHandler(error);
    });
  }
}
