// import { AuthGuard } from './guard/auth.guard';
/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'forgot',
    loadChildren: () => import('./pages/forgot/forgot.module').then(m => m.ForgotPageModule)
  },
  {
    path: 'forgot-otp',
    loadChildren: () => import('./pages/forgot-otp/forgot-otp.module').then(m => m.ForgotOtpPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'register-otp',
    loadChildren: () => import('./pages/register-otp/register-otp.module').then(m => m.RegisterOtpPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./pages/my-profile/my-profile.module').then(m => m.MyProfilePageModule)
  },
  {
    path: 'order-history',
    loadChildren: () => import('./pages/order-history/order-history.module').then(m => m.OrderHistoryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inquiry-otp',
    loadChildren: () => import('./pages/inquiry-otp/inquiry-otp.module').then(m => m.InquiryOtpPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartPageModule)
  },
  {
    path: 'wishlist',
    loadChildren: () => import('./pages/wishlist/wishlist.module').then(m => m.WishlistPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'personal-detail',
    loadChildren: () => import('./pages/personal-detail/personal-detail.module').then(m => m.PersonalDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-personal',
    loadChildren: () => import('./pages/edit-personal/edit-personal.module').then(m => m.EditPersonalPageModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'address-detail',
    loadChildren: () => import('./pages/address-detail/address-detail.module').then(m => m.AddressDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'order-detail',
    loadChildren: () => import('./pages/order-detail/order-detail.module').then(m => m.OrderDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'rating',
    loadChildren: () => import('./pages/rating/rating.module').then(m => m.RatingPageModule)
  },
  {
    path: 'return-order',
    loadChildren: () => import('./pages/return-order/return-order.module').then(m => m.ReturnOrderPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordPagePageModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsPageModule)
  },
  {
    path: 'product-detail',
    loadChildren: () => import('./pages/product-detail/product-detail.module').then(m => m.ProductDetailPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'filter',
    loadChildren: () => import('./pages/filter/filter.module').then(m => m.FilterPageModule)
  },
  {
    path: 'checkout2',
    loadChildren: () => import('./pages/checkout2/checkout2.module').then(m => m.Checkout2PageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'confirmation',
    loadChildren: () => import('./pages/confirmation/confirmation.module').then(m => m.ConfirmationPageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule)
  },
  {
    path: 'product-list',
    loadChildren: () => import('./pages/product-list/product-list.module').then(m => m.ProductListPageModule)
  },
  {
    path: 'sort-modal',
    loadChildren: () => import('./pages/sort-modal/sort-modal.module').then(m => m.SortModalPageModule)
  },
  {
    path: 'offers',
    loadChildren: () => import('./pages/offers/offers.module').then(m => m.OffersPageModule)
  },
  {
    path: 'offers-details',
    loadChildren: () => import('./pages/offers-details/offers-details.module').then(m => m.OffersDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'stripe-pay',
    loadChildren: () => import('./pages/stripe-pay/stripe-pay.module').then(m => m.StripePayPageModule)
  },
  {
    path: 'await-payments',
    loadChildren: () => import('./pages/await-payments/await-payments.module').then(m => m.AwaitPaymentsPageModule)
  },
  {
    path: 'tracking-informations',
    loadChildren: () => import('./pages/tracking-informations/tracking-informations.module').then(m => m.TrackingInformationsPageModule)
  },
  {
    path: 'address-list',
    loadChildren: () => import('./pages/address-list/address-list.module').then(m => m.AddressListPageModule)
  },
  {
    path: 'products-reviews',
    loadChildren: () => import('./pages/products-reviews/products-reviews.module').then(m => m.ProductsReviewsPageModule)
  },
  {
    path: 'languages',
    loadChildren: () => import('./pages/languages/languages.module').then(m => m.LanguagesPageModule)
  },
  {
    path: 'verify',
    loadChildren: () => import('./pages/verify/verify.module').then(m => m.VerifyPageModule)
  },
  {
    path: 'contact-form',
    loadChildren: () => import('./pages/contact-form/contact-form.module').then(m => m.ContactFormPageModule)
  },
  {
    path: 'pages/:id/:name',
    loadChildren: () => import('./pages/app-pages/app-pages.module').then(m => m.AppPagesPageModule)
  },
  {
    path: 'brands',
    loadChildren: () => import('./pages/brands/brands.module').then( m => m.BrandsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
