/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { ApiService } from './../../services/apis.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-sort-modal',
  templateUrl: './sort-modal.page.html',
  styleUrls: ['./sort-modal.page.scss'],
})
export class SortModalPage implements OnInit {
  selectedFilter: any;
  constructor(
    public api: ApiService,
    private modalController: ModalController,
    private navParam: NavParams,
    public util: UtilService
  ) {
    this.selectedFilter = this.navParam.get('id');
  }

  ngOnInit() {
  }
  close(data?) {
    this.modalController.dismiss(data);
  }
}
