/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/apis.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-offers-details',
  templateUrl: './offers-details.page.html',
  styleUrls: ['./offers-details.page.scss'],
})
export class OffersDetailsPage implements OnInit {
  id: any;
  amount: any;
  code: any;
  descriptions: any;
  excludeSale: any;
  expireDate: any;
  freeShipping: any;
  limitUsage: any;
  maxSpend: any;
  minSpend: any;
  type: any;

  productList: any[] = [];
  categoriesList: any[] = [];
  constructor(
    private navParam: NavParams,
    private modalController: ModalController,
    public api: ApiService,
    public util: UtilService
  ) {
    const info = this.navParam.get('info');
    console.log(info);
    if (info && info.code && info.id) {
      this.id = info.id;
      this.amount = info.amount;
      this.code = info.code;
      this.descriptions = info.descriptions;
      this.excludeSale = info.excludeSale;
      this.expireDate = info.expireDate;
      this.freeShipping = info.freeShipping;
      this.limitUsage = info.limitUsage;
      this.maxSpend = info.maxSpend;
      this.minSpend = info.minSpend;
      this.type = info.type;

      if (info.productsId && info.productsId != null && info.productsId !== 'null') {
        this.getProducts(info.productsId);
      }
      if (info.categoriesId && info.categoriesId != null && info.categoriesId !== 'null') {
        this.getCategories(info.categoriesId);
      }
    } else {
      this.close();
    }
  }

  ngOnInit() {
  }

  getProducts(id) {
    this.api.post_public('v1/products/getProductsFromOffers', { ids: id }).then((data: any) => {
      console.log(data);
      if (data && data.status && data.status == 200 && data.data && data.data.length) {
        this.productList = data.data;
      }
    }, error => {
      console.log(error);
      this.util.apiErrorHandler(error);
    }).catch(error => {
      console.log(error);
      this.util.apiErrorHandler(error);
    });
  }

  getCategories(id) {
    this.api.post_public('v1/categories/getCategoriesFromOffers', { ids: id }).then((data: any) => {
      console.log(data);
      if (data && data.status && data.status == 200 && data.data && data.data.length) {
        this.categoriesList = data.data;
      }
    }, error => {
      console.log(error);
      this.util.apiErrorHandler(error);
    }).catch(error => {
      console.log(error);
      this.util.apiErrorHandler(error);
    });
  }

  close() {
    this.modalController.dismiss();
  }

  copyClipBoard() {
    try {
      navigator.clipboard.writeText(this.code).then(data => {
        console.log('Async: Copying to clipboard was successful!');
        this.util.showToast('Copied to clipboard', 'success', 'bottom');
      }, error => {
        console.error('Async: Could not copy text: ', error);
      });
    } catch (error) {
      console.log(error);
    }

  }

}
