/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { Injectable } from '@angular/core';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  cart: any[] = [];
  totalItems: any = 0;
  subTotal: any = 0;
  serviceTax: any = 0;
  discount: any = 0;
  grandTotal: any = 0;

  couponCode: any;
  haveFreeShipping: boolean = false;
  deliveryPrice: any = 0;
  shippingInfo: any;

  deliveryAddress: any;
  constructor(public util: UtilService) {
    if (((x) => { try { JSON.parse(x); return true; } catch (e) { return false; } })(localStorage.getItem('appCarts'))) {
      const userCart = JSON.parse(localStorage.getItem('appCarts'));
      if (userCart && userCart !== null && userCart !== 'null') {
        if (userCart && userCart.length > 0) {
          this.cart = userCart;
          this.calcuate();
        } else {
          this.calcuate();
        }
      } else {
        this.calcuate();
      }
    } else {
      this.calcuate();
    }

  }

  addItem(item) {
    console.log(item);
    this.cart = [...this.cart, item];
    this.calcuate();
  }

  addQuantity(index) {
    this.cart[index].quantity = this.cart[index].quantity + 1;
    this.calcuate();
  }

  async calcuate() {
    console.log('calculate this items=>', this.cart);
    console.log('shipping price=>!!', this.deliveryPrice);
    this.subTotal = 0;
    this.totalItems = 0;
    this.cart.forEach((element => {
      let subPrice = 0;
      if (element && element.discount > 0) {
        subPrice = subPrice + element.sell_price;
      } else {
        subPrice = subPrice + element.original_price;
      }
      subPrice = subPrice * element.quantity;
      this.totalItems = this.totalItems + element.quantity;
      this.subTotal = this.subTotal + subPrice;

      if (((x) => { try { JSON.parse(x); return true; } catch (e) { return false; } })(element.extra_field)) {
        const extra_field = JSON.parse(element.extra_field);
        if (extra_field && extra_field.translation) {
          const currentTranslation = extra_field.translation.filter(x => x.id == localStorage.getItem('translateKey'));
          if (currentTranslation && currentTranslation.length) {
            element.name = currentTranslation[0].title;
            element.descriptions = currentTranslation[0].descriptions;
          }
        }
      }



      if (((x) => { try { JSON.parse(x); return true; } catch (e) { return false; } })(element.categories_extra_field)) {
        const extra_field = JSON.parse(element.categories_extra_field);
        if (extra_field && extra_field.translation) {
          const currentTranslation = extra_field.translation.filter(x => x.id == localStorage.getItem('translateKey'));
          if (currentTranslation && currentTranslation.length) {
            element.cate_name = currentTranslation[0].title;
          }
        }
      }

      if (((x) => { try { JSON.parse(x); return true; } catch (e) { return false; } })(element.sub_categories_extra_field)) {
        const extra_field = JSON.parse(element.sub_categories_extra_field);
        if (extra_field && extra_field.translation) {
          const currentTranslation = extra_field.translation.filter(x => x.id == localStorage.getItem('translateKey'));
          if (currentTranslation && currentTranslation.length) {
            element.sub_categories_name = currentTranslation[0].title;
          }
        }
      }

      if (((x) => { try { JSON.parse(x); return true; } catch (e) { return false; } })(element.brands_extra_field)) {
        const extra_field = JSON.parse(element.brands_extra_field);
        if (extra_field && extra_field.translation) {
          const currentTranslation = extra_field.translation.filter(x => x.id == localStorage.getItem('translateKey'));
          if (currentTranslation && currentTranslation.length) {
            element.brand_name = currentTranslation[0].title;
          }
        }
      }
    }));
    console.log('shiping info', this.shippingInfo);
    if (this.shippingInfo && this.shippingInfo && this.shippingInfo.extra_field && this.shippingInfo.extra_field.price) {
      this.deliveryPrice = parseFloat(this.shippingInfo.extra_field.price.price);
      console.log('delivery price', this.deliveryPrice);
    }
    if (this.couponCode && this.couponCode != null && this.couponCode.amount) {
      console.log('calculate this coupon', this.couponCode);
      console.log('to remove sell?', this.couponCode.excludeSale) // 0 = NO || 1 = Yes
      if (this.couponCode.categoriesId && this.couponCode.categoriesId != null && this.couponCode.categoriesId != 'null' && this.couponCode.type == 1) {
        // apply offer on products match with categories id
        const catesIds = this.couponCode.categoriesId.split(',').map(Number);
        console.log('apply coupon on categories id', catesIds);
        this.cart.forEach(element => {
          let discount = 0;
          if (catesIds.includes(element.cate_id)) {
            console.log('apply offers');
            function percentage(num, per) {
              return (num / 100) * per;
            }
            const price = this.couponCode.excludeSale == 1 ? element.original_price : element.sell_price;
            discount = percentage(parseFloat(price).toFixed(2), this.couponCode.amount);
            discount = discount * element.quantity;
            console.log('dicount', discount);
            this.discount = this.discount + discount;
          }
        });
      }
      // calculate discount on product based. if categories is empty;

      if (!this.couponCode.categoriesId && this.couponCode.productsId &&
        this.couponCode.productsId != null && this.couponCode.productsId != 'null' && this.couponCode.type == 1) {
        console.log('calculate product discount');

        const prodIds = this.couponCode.productsId.split(',').map(Number);
        console.log('apply coupon on prodIds id', prodIds);
        this.cart.forEach(element => {
          let discount = 0;
          if (prodIds.includes(parseInt(element.id))) {
            console.log('apply offers');
            function percentage(num, per) {
              return (num / 100) * per;
            }
            const price = this.couponCode.excludeSale == 1 ? element.original_price : element.sell_price;
            discount = percentage(parseFloat(price).toFixed(2), this.couponCode.amount);
            discount = discount * element.quantity;
            console.log('dicount', discount);
            this.discount = this.discount + discount;
          }
        });

      }

      // products or categories not found in offers... so calculate offers from all the products in cart
      if (!this.couponCode.categoriesId && !this.couponCode.productsId && this.couponCode.type == 1) { // no products but add percentage in every products
        console.log('calculate it with all the products');
        this.cart.forEach(element => {
          let discount = 0;
          function percentage(num, per) {
            return (num / 100) * per;
          }
          const price = this.couponCode.excludeSale == 1 ? element.original_price : element.sell_price;
          discount = percentage(parseFloat(price).toFixed(2), this.couponCode.amount);
          discount = discount * element.quantity;
          console.log('dicount', discount);
          this.discount = this.discount + discount;
        });

      }

      if (this.couponCode.type == 2) { // fixed cart discount
        console.log('fixed cart discount');
        this.discount = this.couponCode.amount;
      }

      if (this.couponCode.type == 3) {
        console.log('fixed product discount');
        this.cart.forEach(element => {
          let discount = 0;
          discount = parseFloat(this.couponCode.amount) * element.quantity;
          console.log('dicount', discount);
          this.discount = this.discount + discount;
        });
      }
    }
    console.log('discount=>', this.discount);
    this.grandTotal = this.subTotal - this.discount;
    this.grandTotal = this.grandTotal + this.deliveryPrice;
    // this.serviceTax = parseFloat(this.serviceTax).toFixed(2);
    this.serviceTax = Number(this.serviceTax);
    console.log('-------------');
    console.log(this.serviceTax,typeof this.serviceTax);
    this.grandTotal = this.grandTotal + this.serviceTax;
    console.log('shipping price=>', this.deliveryPrice);
    localStorage.removeItem('appCarts');
    localStorage.setItem('appCarts', JSON.stringify(this.cart));
  }

  clearData() {
    this.subTotal = 0;
    this.grandTotal = 0;
    this.deliveryPrice = 0;
    this.discount = 0;
    this.cart = [];
    this.deliveryAddress = null;
    this.couponCode = null;
    this.totalItems = 0;
    this.haveFreeShipping = false;
    localStorage.removeItem('appCarts');
  }

  removeItem(item) {
    console.log('remove this item from cart');
    this.cart = this.cart.filter(x => x !== item);
    this.calcuate();
  }


  degreesToRadians(degrees) {
    return degrees * Math.PI / 180;
  }

  distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
    console.log(lat1, lon1, lat2, lon2);
    const earthRadiusKm = 6371;

    const dLat = this.degreesToRadians(lat2 - lat1);
    const dLon = this.degreesToRadians(lon2 - lon1);

    lat1 = this.degreesToRadians(lat1);
    lat2 = this.degreesToRadians(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadiusKm * c;
  }

}
