import { UtilService } from 'src/app/services/util.service';
/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-tracking-informations',
  templateUrl: './tracking-informations.page.html',
  styleUrls: ['./tracking-informations.page.scss'],
})
export class TrackingInformationsPage implements OnInit {
  id: any = '';
  info: any = '';
  constructor(
    private modalController: ModalController,
    private navParam: NavParams,
    public util: UtilService
  ) {
    this.id = this.navParam.get('id');
    this.info = this.navParam.get('info');
  }

  ngOnInit() {
  }

  onClose() {
    this.modalController.dismiss();
  }
}
