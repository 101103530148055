/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { ApiService } from './../../services/apis.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.page.html',
  styleUrls: ['./filter.page.scss'],
})
export class FilterPage implements OnInit {

  subCat = [
      'Kurtas & Suits',
      'Kurtis, Tunics & Tops',
      'Ethnic Dresses',
      'Leggings, Salwars & Churidars',
      'Skirts & Palazzos',
      'Sarees & Blouses',
      'Dress Materials',
      'Lehenga Cholis',
      'Dupattas & Shawls',
      'Jackets & Waistcoats',
      'Flats'
  ];
  constructor(private modalCtrl: ModalController, public api: ApiService, public util: UtilService) { }

  ngOnInit() {
  }

  dismiss() {
      this.modalCtrl.dismiss();
  }

}
