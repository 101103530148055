/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, AlertController, ToastController, NavController } from '@ionic/angular';
import Swal from 'sweetalert2';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
@Injectable({
  providedIn: 'root'
})
export class UtilService {

  isLoading = false;
  public translations: any[] = [];
  public default_country_code: any = '';
  public userInfo: any;
  public general: any;
  public settings: any;
  // public wishlist: any;
  // public wishPro = [];

  public deviceType: any = 'desktop';
  public wishlistIds: any[] = [];
  public appLogo: any;
  public direction: any;
  public app_status: any;
  public app_closed_message: any;
  public appName: any = 'YOUR APP NAME';
  public isWebOrPWA: boolean = false;
  public app_color: any = '#ff2600';

  public cside: any = 'left';
  public currecny: any = '$';
  public countrys: any[] = [];
  public user_login: any = '0';
  public smsGateway: any = '0';
  orderStatus = [
    'Created', // 0
    'Completed',// 1
    'Pending Payments', // 2
    'Cancelled', // 3
    'Refunded', // 4
    'Returned', // 5
  ];
  public user_verification: any = 0;
  constructor(public loadingCtrl: LoadingController, public alertCtrl: AlertController,
    private toastCtrl: ToastController, private navCtrl: NavController, private router: Router) {
  }

  translate(str) {
    if (this.translations[str]) {
      return this.translations[str];
    }
    return str;
  }

  openCart() {
    this.navCtrl.navigateRoot(['tabs/cart']);
  }

  async show(msg?) {
    this.isLoading = true;
    return await this.loadingCtrl.create({
      message: msg,
      spinner: 'bubbles',
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async hide() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss().then(() => console.log('dismissed'));
  }

  /*
    Show Warning Alert Message
    param : msg = message to display
    Call this method to show Warning Alert,
    */
  async showWarningAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: 'Warning',
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
    await Haptics.impact({ style: ImpactStyle.Medium });
  }

  async showSimpleAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: '',
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
    await Haptics.impact({ style: ImpactStyle.Medium });
  }



  /*
  Show Error Alert Message
  param : msg = message to display
  Call this method to show Error Alert,
  */
  async showErrorAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
    await Haptics.impact({ style: ImpactStyle.Medium });
  }

  /*
    param : email = email to verify
    Call this method to get verify email
    */
  async getEmailFilter(email) {
    const emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
    if (!(emailfilter.test(email))) {
      const alert = await this.alertCtrl.create({
        header: 'Warning',
        message: 'Please enter valid email',
        buttons: ['OK']
      });
      await alert.present();
      return false;
    } else {
      return true;
    }
  }


  /*
    Show Toast Message on Screen
    param : msg = message to display, color= background
    color of toast example dark,danger,light. position  = position of message example top,bottom
    Call this method to show toast message
    */

  async showToast(msg, colors, positon) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
      color: colors,
      position: positon
    });
    toast.present();
    await Haptics.impact({ style: ImpactStyle.Medium });
  }

  async shoNotification(msg, colors, positon) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 4000,
      color: colors,
      position: positon,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            // console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
    await Haptics.impact({ style: ImpactStyle.Medium });
  }

  async errorToast(msg, color?) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
      color: color ? color : 'danger',
      position: 'bottom'
    });
    toast.present();
    await Haptics.impact({ style: ImpactStyle.Medium });
  }

  apiErrorHandler(err) {
    if (err && err.status == 401 && err.error.error) {
      this.errorToast(err.error.error);
      this.router.navigateByUrl('/login');
      return false;
    }
    if (err && err.status == 500 && err.error.error) {
      this.errorToast(err.error.error);
      return false;
    }
    if (err.status == -1) {
      this.errorToast(this.translate('Failed To Connect With Server'));
    } else if (err.status == 401) {
      this.errorToast(this.translate('Unauthorized Request!'));
      this.router.navigateByUrl('/login');
    } else if (err.status == 500) {
      this.errorToast(this.translate('Somethimg Went Wrong'));
    } else if (err.status == 422 && err.error.error) {
      this.errorToast(err.error.error);
    } else {
      this.errorToast(this.translate('Something went wrong'));
    }
  }


  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
}
