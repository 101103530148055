/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { ApiService } from './../../services/apis.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { UtilService } from 'src/app/services/util.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.page.html',
  styleUrls: ['./rating.page.scss'],
})
export class RatingPage implements OnInit {
  rate: any = 2;
  reviews: any = '';
  productId: any = '';
  orderId: any = '';
  name: any = '';
  constructor(
    private navCtrl: NavController,
    public api: ApiService,
    public util: UtilService,
    private navParam: NavParams,
    private route: ActivatedRoute,
    private modalCtrl: ModalController) {
    if (this.navParam.get('id') && this.navParam.get('productId')) {
      this.orderId = this.navParam.get('id');
      this.orderId = this.navParam.get('productId');
      this.name = this.navParam.get('name');
    } else {
      this.goToBack();
    }
  }

  ngOnInit() {
  }

  goToBack() {
    this.modalCtrl.dismiss();
  }

  saveRatings() {
    if (this.reviews == '' || !this.reviews) {
      this.util.errorToast('Please write reviews');
      return false;
    }
    const param = {
      uid: localStorage.getItem('uid'),
      rating: this.rate,
      review: this.reviews,
      orderId: this.orderId,
      productId: this.productId
    }
    console.log(param);
    this.util.show();
    this.api.post_private('v1/reviews/create', param).then((data: any) => {
      console.log(data);
      this.util.hide();
      if (data && data.status && data.status == 200) {
        this.util.showToast('Reviews Saved', 'success', 'bottom');
        this.goToBack();
      }
    }, error => {
      console.log(error);
      this.util.hide();
      this.util.apiErrorHandler(error);
    }).catch(error => {
      console.log(error);
      this.util.hide();
      this.util.apiErrorHandler(error);
    });
  }
}
