/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
@Injectable({
    providedIn: 'root'
})
export class ApiService {
    baseUrl: any = '';
    mediaURL: any = '';
    confirmationResult: firebase.default.auth.ConfirmationResult;
    constructor(
      private http: HttpClient,
      private fireAuth: AngularFireAuth
    ) {
      this.baseUrl = environment.baseUrl;
      this.mediaURL = environment.imageUrl;
    }
    translate(str) {
      return str;
    }

    alerts(title, message, type) {
      // Swal.fire(
      //   title,
      //   message,
      //   type
      // );
    }

    uploadFile(files: File[]) {
        const formData = new FormData();
        Array.from(files).forEach(f => formData.append('userfile', f));
        return this.http.post(this.baseUrl + 'users/upload_image', formData);
    }

    getCurrencyCode() {
        return 'none';
    }

    getCurrecySymbol() {
        return 'none';
    }

    public signInWithPhoneNumber(recaptchaVerifier, phoneNumber) {
      return new Promise<any>((resolve, reject) => {

        this.fireAuth.signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
          .then((confirmationResult) => {
            this.confirmationResult = confirmationResult;
            resolve(confirmationResult);
          }).catch((error) => {
            console.log(error);
            reject('SMS not sent');
          });
      });
    }
    public async enterVerificationCode(code) {
      return new Promise<any>((resolve, reject) => {
        this.confirmationResult.confirm(code).then(async (result) => {
          console.log(result);
          const user = result.user;
          resolve(user);
        }).catch((error) => {
          reject(error.message);
        });

      });
    }

    sendNotification(msg, title, id) {
      // const body = {
      //   app_id: environment.onesignal.appId,
      //   include_player_ids: [id],
      //   headings: { en: title },
      //   contents: { en: msg },
      //   data: { task: msg }
      // };
      // const header = {
      //   headers: new HttpHeaders()
      //     .set('Content-Type', 'application/json')
      //     .set('Authorization', `Basic ${environment.onesignal.restKey}`)
      // };
      // return this.http.post('https://onesignal.com/api/v1/notifications', body, header);
    }

    JSON_to_URLEncoded(element, key?, list?) {
        let new_list = list || [];
        if (typeof element == 'object') {
            for (let idx in element) {
                this.JSON_to_URLEncoded(
                    element[idx],
                    key ? key + '[' + idx + ']' : idx,
                    new_list
                );
            }
        } else {
            new_list.push(key + '=' + encodeURIComponent(element));
        }
        return new_list.join('&');
    }

    public post_private(url, body): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const header = {
                headers: new HttpHeaders()
                  .set('Content-Type', 'application/x-www-form-urlencoded')
                  .set('Authorization', `Bearer ${localStorage.getItem('token')}`)
            };
            const param = this.JSON_to_URLEncoded(body);
            console.log(param);
            this.http.post(this.baseUrl + url, param, header).subscribe((data) => {
                resolve(data);
            }, error => {
                reject(error);
            });
        });
    }

    public post_public(url, body): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const header = {
                headers: new HttpHeaders()
                  .set('Content-Type', 'application/x-www-form-urlencoded')
            };
            const param = this.JSON_to_URLEncoded(body);
            console.log(param);
            this.http.post(this.baseUrl + url, param, header).subscribe((data) => {
                resolve(data);
            }, error => {
                reject(error);
            });
        });
    }

    public uploadImage(url, blobData, ext): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const formData = new FormData();
            formData.append('image', blobData, `myimage.${ext}`);
            this.http.post(this.baseUrl + url, formData).subscribe((data) => {
                resolve(data);
            }, error => {
                reject(error);
            });
        });
    }

    public get_public(url): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const header = {
                headers: new HttpHeaders()
                  .set('Content-Type', 'application/x-www-form-urlencoded')
            };
            this.http.get(this.baseUrl + url, header).subscribe((data) => {
                resolve(data);
            }, error => {
                reject(error);
            });
        });
    }


    public get_private(url): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const header = {
                headers: new HttpHeaders()
                  .set('Content-Type', 'application/x-www-form-urlencoded')
                  .set('Authorization', `Bearer ${localStorage.getItem('token')}`)
            };
            this.http.get(this.baseUrl + url, header).subscribe((data) => {
                resolve(data);
            }, error => {
                reject(error);
            });
        });
    }

    public externalGet(url): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const header = {
                headers: new HttpHeaders()
                  .set('Content-Type', 'application/x-www-form-urlencoded')
            };
            this.http.get(url, header).subscribe((data) => {
                resolve(data);
            }, error => {
                reject(error);
            });
        });
    }

    nativePost(url, post) {
      // console.log(this.baseUrl + url, post);
      // return this.nativeHttp.post(this.baseUrl + url, post, {
      // });
    }

    httpGet(url, key) {
        const header = {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', `Bearer ${key}`)
        };
        return this.http.get(url, header);
    }

    externalPost(url, body, key) {
        const header = {
            headers: new HttpHeaders()
              .set('Content-Type', 'application/x-www-form-urlencoded')
              .set('Authorization', `Bearer ${key}`)
        };
        const order = this.JSON_to_URLEncoded(body);
        console.log(order);
        return this.http.post(url, order, header);
    }
}
