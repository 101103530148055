/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/apis.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-products-reviews',
  templateUrl: './products-reviews.page.html',
  styleUrls: ['./products-reviews.page.scss'],
})
export class ProductsReviewsPage implements OnInit {
  id: any = '';
  name: any = '';
  dummy: any[] = [];
  list: any[] = [];
  constructor(
    private navParam: NavParams,
    public api: ApiService,
    public util: UtilService,
    private modalController: ModalController
  ) {
    this.id = this.navParam.get('id');
    if (this.id && this.id != '') {
      this.name = this.navParam.get('name');
      this.getReviews();
    }
  }

  getReviews() {
    this.dummy = Array(10);
    this.api.post_public('v1/reviews/getProductsReviews', { id: this.id }).then((data: any) => {
      console.log(data);
      this.dummy = [];
      if (data && data.status && data.status == 200 && data.data && data.data.length) {
        this.list = data.data;
      }
    }, error => {
      console.log(error);
      this.dummy = [];
      this.util.apiErrorHandler(error);
    }).catch(error => {
      console.log(error);
      this.dummy = [];
      this.util.apiErrorHandler(error);
    });
  }

  ngOnInit() {
  }

  close() {
    this.modalController.dismiss();
  }
}
