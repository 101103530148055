/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/apis.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-address-detail',
  templateUrl: './address-detail.page.html',
  styleUrls: ['./address-detail.page.scss'],
})
export class AddressDetailPage implements OnInit {
  id: any;
  action: any = 'new';

  address: any = '';
  landmark: any = '';
  locality: any = '';
  pincode: any = '';

  title: any = 'home';
  savedCountries: any[] = [];
  savedZones: any[] = [];
  city: any = '';
  state_id: any = '';
  country_id: any = '';

  savedStates: any[] = [];
  constructor(
    private navParam: NavParams,
    public api: ApiService,
    public util: UtilService,
    private modalController: ModalController
  ) {
    this.action = this.navParam.get('action');
    if (this.action == 'edit') {
      this.id = this.navParam.get('id');
      this.getAddressDetails();
    }
    console.log('action=>', this.action);
    this.getSavedCountries();
    this.getAllZones();
  }

  ngOnInit() {

  }

  getAddressDetails() {
    this.util.show();
    this.api.post_private('v1/address/getById', { id: this.id }).then((data: any) => {
      console.log(data);
      this.util.hide();
      if (data && data.status && data.status == 200 && data.data) {
        const info = data.data;
        console.log('info', info);
        this.address = info.address;
        this.city = info.city_name;
        this.country_id = info.country_id;
        this.landmark = info.landmark;
        this.locality = info.locality;
        this.pincode = info.pincode;
        this.state_id = info.state_id;
        this.title = info.title;
      }
    }, error => {
      console.log(error);
      this.util.hide();
      this.util.apiErrorHandler(error);
    }).catch(error => {
      console.log(error);
      this.util.hide();
      this.util.apiErrorHandler(error);
    });
  }

  countrySelected() {
    console.log(this.country_id);
    this.savedZones.forEach(element => {
      element.zone_state_names.forEach(states => {
        states['zone_id'] = element.id;
      });
      console.log(element);
      const items = element.zone_state_names.filter(x => x.country_id == this.country_id);
      this.savedStates.push(...items);
    });
    console.log(this.savedStates);
  }

  getAllZones() {
    this.savedZones = [];
    this.api.get_public('v1/zones/getAll').then((data: any) => {
      console.log(data);
      if (data && data.status && data.status == 200 && data.data && data.data.length) {
        data.data.forEach(element => {
          element.extra_field = JSON.parse(element.extra_field);
          element.zone_state_names = JSON.parse(element.zone_state_names);
        });
        this.savedZones = data.data;
      }
    }, error => {
      console.log(error);
      this.savedZones = [];
      this.util.apiErrorHandler(error);
    }).catch(error => {
      console.log(error);
      this.savedZones = [];
      this.util.apiErrorHandler(error);
    });
  }



  getSavedCountries() {
    this.savedCountries = [];
    this.api.get_public('v1/shippingCountries/getAll').then((data: any) => {
      console.log(data);
      if (data && data.status && data.status == 200 && data.data && data.data.length) {
        this.savedCountries = data.data;
      }
    }, error => {
      this.savedCountries = [];
      console.log('Error', error);
      this.util.apiErrorHandler(error);
    }).catch(error => {
      this.savedCountries = [];
      console.log('Err', error);
      this.util.apiErrorHandler(error);
    });
  }


  adddNew() {
    console.log(this.country_id, this.state_id);

    if (this.address == '' || this.landmark == '' || this.locality == '' || this.pincode == '' || this.country_id == '' || !this.country_id ||
      this.state_id == '' || !this.state_id || this.city == '' || !this.city) {
      this.util.errorToast(this.util.translate('All Fields are required'));
      return false;
    }

    this.util.show();
    const country = this.savedCountries.filter(x => x.country_id == this.country_id);
    const state = this.savedStates.filter(x => x.id == this.state_id);
    console.log(country);
    console.log(state);
    const extra = {
      zone_id: state[0].zone_id
    }
    const param = {
      uid: localStorage.getItem('uid'),
      address: this.address,
      lat: 'NA',
      lng: "NA",
      locality: this.locality,
      landmark: this.landmark,
      pincode: this.pincode,
      status: 1,
      title: this.title,
      country_name: country[0].name,
      country_id: this.country_id,
      state_name: state[0].name,
      state_id: this.state_id,
      city_name: this.city,
      extra_field: JSON.stringify(extra)
    };
    this.api.post_private('v1/address/save', param).then((data: any) => {
      this.util.hide();
      if (data && data.status == 200) {
        this.cancel('ok', 'ok');
        this.util.showToast(this.util.translate('Address added'), 'success', 'bottom');
      } else {
        this.util.errorToast(this.util.translate('Something went wrong'));
      }
    }, error => {
      console.log(error);
      this.util.hide();
      this.util.apiErrorHandler(error);
    }).catch((error) => {
      console.log(error);
      this.util.hide();
      this.util.apiErrorHandler(error);
    });

  }

  updateAddress() {
    console.log(this.country_id, this.state_id);

    if (this.address == '' || this.landmark == '' || this.locality == '' || this.pincode == '' || this.country_id == '' || !this.country_id ||
      this.state_id == '' || !this.state_id || this.city == '' || !this.city) {
      this.util.errorToast(this.util.translate('All Fields are required'));
      return false;
    }

    this.util.show();
    const country = this.savedCountries.filter(x => x.country_id == this.country_id);
    const state = this.savedStates.filter(x => x.id == this.state_id);
    console.log(country);
    console.log(state);
    const extra = {
      zone_id: state[0].zone_id
    }
    const param = {
      uid: localStorage.getItem('uid'),
      address: this.address,
      lat: 'NA',
      lng: "NA",
      locality: this.locality,
      landmark: this.landmark,
      pincode: this.pincode,
      status: 1,
      id: this.id,
      title: this.title,
      country_name: country[0].name,
      country_id: this.country_id,
      state_name: state[0].name,
      state_id: this.state_id,
      city_name: this.city,
      extra_field: JSON.stringify(extra)
    };
    this.api.post_private('v1/address/update', param).then((data: any) => {
      this.util.hide();
      if (data && data.status == 200) {
        this.cancel('ok', 'ok');
        this.util.showToast(this.util.translate('Address added'), 'success', 'bottom');
      } else {
        this.util.errorToast(this.util.translate('Something went wrong'));
      }
    }, error => {
      console.log(error);
      this.util.hide();
      this.util.apiErrorHandler(error);
    }).catch((error) => {
      console.log(error);
      this.util.hide();
      this.util.apiErrorHandler(error);
    });
  }

  cancel(role?, data?) {
    this.modalController.dismiss(role, data);
  }
}
