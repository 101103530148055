/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { Capacitor } from '@capacitor/core';
import { ApiService } from './services/apis.service';
import { Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { UtilService } from './services/util.service';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { CartService } from './services/cart.service';
import {
  PushNotificationSchema,
  PushNotifications,
} from '@capacitor/push-notifications';
import { FcmService } from './services/fcm.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  selCat;
  categories = [];
  settings;

  constructor(
    private router: Router,
    private platform: Platform,
    public api: ApiService,
    public util: UtilService,
    private cart: CartService,
    private fcm: FcmService,
    private navCtrl: NavController,
    private alertController: AlertController) {
    if (this.platform.platforms().includes('desktop') || this.platform.platforms().includes('pwa') || this.platform.platforms().includes('mobileweb')) {
      this.util.isWebOrPWA = true;
    }
    if (localStorage.getItem('uid') != null && localStorage.getItem('uid') && localStorage.getItem('uid') !== '' && localStorage.getItem('uid') !== 'null') {
      this.getUserByID();
      this.getWishlistByUserID();
    }
    if (this.platform.platforms().includes('desktop') || this.platform.platforms().includes('pwa') || this.platform.platforms().includes('mobileweb')) {
      this.util.isWebOrPWA = true;
      this.fcm.requestPermission();
    }
    const selectedLanguage = localStorage.getItem('translateKey');
    if (selectedLanguage != null && selectedLanguage && selectedLanguage !== '' && selectedLanguage !== 'null') {
      this.getDefaultSettingsById();
    } else {
      this.getAppSettings();
    }
    const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
    if (isPushNotificationsAvailable) {
      this.initNotification();
    }
    this.getAllCategories();
  }

  initNotification() {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive == 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener(
      'registration',
      (token: any) => {
        console.log('MY push Token', token.value);
        localStorage.setItem('pushToken', token.value);
        const uid = localStorage.getItem('uid');
        if (uid != null && uid && uid !== '' && uid !== 'null') {
          this.updateFCMToken();
        }
      },
    );

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log(error);
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Nticiation', notification);
        console.log('recved', notification.title, notification.body);
        this.presentAlert(notification.title, notification.body);
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: any) => {
        // alert('Push action performed: ' + JSON.stringify(notification));
        console.log('Notifcation actione', notification);
      },
    );
  }

  async presentAlert(title, body) {
    const alert = await this.alertController.create({
      header: 'Notifications',
      subHeader: title,
      message: body,
      buttons: ['OK']
    });

    await alert.present();
  }

  updateFCMToken() {
    const param = {
      id: localStorage.getItem('uid'),
      fcm_token: localStorage.getItem('pushToken') && localStorage.getItem('pushToken') != null ? localStorage.getItem('pushToken') : 'NA'
    }
    this.api.post_private('v1/profile/update', param).then((data: any) => {
      console.log(data);
    }, error => {
      console.log(error);
    }).catch(error => {
      console.log(error);
    });
  }

  getUserByID() {
    const body = {
      id: localStorage.getItem('uid')
    }
    this.api.post_private('v1/profile/getProfileById', body).then((data: any) => {
      if (data && data.success && data.status == 200 && data.data && data.data.id) {
        console.log("USER DATA == ", data);
        this.util.userInfo = data.data;
      }
      else {
        localStorage.removeItem('uid');
        localStorage.removeItem('token');
      }
    }, err => {
      localStorage.removeItem('uid');
      localStorage.removeItem('token');
      this.util.userInfo = null;
      console.log(err);
    }).catch((err) => {
      localStorage.removeItem('uid');
      localStorage.removeItem('token');
      this.util.userInfo = null;
      console.log(err);
    });
  }

  getDefaultSettingsById() {
    const param = {
      id: localStorage.getItem('translateKey')
    }
    this.api.post_public('v1/setttings/getAppSettingsByLanguageId', param).then((data: any) => {
      console.log('app settings', data);
      if (data && data.status == 200 && data.success == true) {

        const general = data && data.data && data.data.general ? data.data.general : null;

        if (general && general !== null) {
          this.util.general = general;
          this.cart.serviceTax = general.tax && general.tax > 0 ? parseFloat(general.tax).toFixed(2) : 0;
          this.util.appName = general.store_name;
          console.log('general??', general, this.util.general);
        }
        const settings = data && data.data && data.data.settings ? data.data.settings : null;
        if (settings && settings !== null) {
          this.util.appLogo = settings.logo;
          this.util.direction = settings.appDirection;
          this.util.cside = settings.currencySide;
          this.util.currecny = settings.currencySymbol;
          this.util.app_status = settings.app_status == 1 ? true : false;
          this.util.user_login = settings.user_login;
          this.util.app_closed_message = settings.app_status_message;
          this.util.smsGateway = settings.sms_name;
          this.util.user_verification = settings.user_verify_with;
          if (((x) => { try { JSON.parse(x); return true; } catch (e) { return false } })(settings.country_modal)) {
            this.util.countrys = JSON.parse(settings.country_modal);
          }
          this.util.default_country_code = settings && settings.default_country_code ? settings.default_country_code : '91';
          console.log(localStorage.getItem('theme'));
          this.util.app_color = settings.app_color;
          document.documentElement.dir = this.util.direction;
          // document.documentElement.dir = 'ltr';
        }

        const lang = data && data.data && data.data.language ? data.data.language : null;
        if (lang && lang !== null) {
          this.util.translations = JSON.parse(lang.content);
          localStorage.setItem('translateKey', lang.id);
        }

      }
    }, error => {
      console.log(error);
      this.util.apiErrorHandler(error);
    }).catch(error => {
      console.log(error);
      this.util.apiErrorHandler(error);
    });
  }

  getAppSettings() {
    this.api.get_public('v1/setttings/getDefaultSettings').then((data: any) => {
      console.log('app settings', data);
      if (data && data.success == true && data.status == 200) {
        const general = data && data.data && data.data.general ? data.data.general : null;
        if (general && general !== null) {
          this.util.general = data.data.general;
          this.cart.serviceTax = general.tax && general.tax > 0 ? general.tax : 0;
          this.util.appName = general.store_name;
        }
        console.log("GENERAL === ", this.util.general);
        console.log("APP === ", data.data);

        const settings = data && data.data && data.data.settings ? data.data.settings : null;
        if (settings && settings !== null) {
          this.util.appLogo = settings.logo;
          this.util.direction = settings.appDirection;
          this.util.cside = settings.currencySide;
          this.util.currecny = settings.currencySymbol;
          this.util.app_status = settings.app_status == 1 ? true : false;
          this.util.user_login = settings.user_login;
          this.util.app_closed_message = settings.app_status_message;
          this.util.smsGateway = settings.sms_name;
          this.util.user_verification = settings.user_verify_with;
          if (((x) => { try { JSON.parse(x); return true; } catch (e) { return false } })(settings.country_modal)) {
            this.util.countrys = JSON.parse(settings.country_modal);
          }
          this.util.default_country_code = settings && settings.default_country_code ? settings.default_country_code : '91';
          console.log(localStorage.getItem('theme'));
          this.util.app_color = settings.app_color;
          document.documentElement.dir = this.util.direction;
          // document.documentElement.dir = 'ltr';
        }

        const lang = data && data.data && data.data.language ? data.data.language : null;
        if (lang && lang !== null) {
          this.util.translations = JSON.parse(lang.content);
          localStorage.setItem('translateKey', lang.id);
        }

      }
    }, err => {
      console.log(err);
      this.util.errorToast(this.util.translate('Something went wrong...'));
    }).catch((err) => {
      console.log(err);
      this.util.errorToast(this.util.translate('Something went wrong...'))
    });
  }

  getAllCategories() {
    this.api.get_public('v1/categories/getHomeCategories').then((data: any) => {
      console.log(data);
      if (data && data.success && data.status == 200) {
        this.categories = data.data;
        this.categories.forEach(element => {
          element['sub_categories'] = [];
        });
        console.log(this.categories);
        this.api.get_public('v1/sub_cate/getUserSubCategories').then((data: any) => {
          console.log('sub categories->>>>', data);
          if (data && data.status == 200 && data.data && data.data.length) {
            this.categories.forEach(element => {
              element['sub_categories'] = data.data.filter(x => x.cate_id == element.id);
            });
          }
        }).catch(error => {
          this.util.errorToast(this.util.translate('Something went wrong...'));
          console.log(error);
        });
      }
    });
  }

  getWishlistByUserID() {
    const body = {
      id: localStorage.getItem('uid')
    }
    this.api.post_private('v1/wishlist/getByUID', body).then((data: any) => {
      console.log(">>>>>====<<<<<", data);
      if (data && data.success && data.status == 200) {
        if (data && data.data.length > 0) {
          console.log("Have List", data.data);
          this.util.wishlistIds = data.data.map(x => x.product_id);
          console.log('wishlist ids', this.util.wishlistIds);
        }
      }
    }, err => {
      console.log(err);
    }).catch((err) => {
      console.log(err);
    });
  }

  // selCategory(val) {
  //   this.selCat = val;
  // }

  goToHome() {
    this.selCat = null;
    this.navCtrl.navigateRoot(['tabs']);
  }

  goToProducts(val) {
    const navData: NavigationExtras = {
      queryParams: {
        id: val
      }
    };
    this.selCat = null;
    this.navCtrl.navigateRoot(['/products'], navData);
  }

  goToOrderHistory() {
    this.selCat = null;
    this.navCtrl.navigateRoot(['/order-history']);
  }

  goToWallet() {
    this.selCat = null;
    this.navCtrl.navigateRoot(['/wallet']);
  }

  goToMyProfile() {
    this.selCat = null;
    this.navCtrl.navigateRoot(['tabs/my-profile']);
  }

  goToWishlist() {
    this.selCat = null;
    this.navCtrl.navigateRoot(['/wishlist']);
  }

  goToNotifications() {
    this.selCat = null;
    this.navCtrl.navigateRoot(['/notifications']);
  }

  goToChangePassword() {
    this.selCat = null;
    this.navCtrl.navigateRoot(['/change-password']);
  }

  goToGetInquiry() {
    this.selCat = null;
    this.navCtrl.navigateRoot(['/get-inquiry']);
  }

  openOffers() {
    this.selCat = null;
    this.navCtrl.navigateRoot(['offers']);
  }

  openLanguages() {
    this.selCat = null;
    this.navCtrl.navigateRoot(['languages']);
  }

  contactUs() {
    this.selCat = null;
    this.router.navigate(['contact-form']);
  }

  openPage(id, name) {
    this.navCtrl.navigateRoot('/pages/' + id + '/' + name);
  }

  logout() {
    this.util.show();
    this.api.post_private('v1/auth/logout', {}).then((data) => {
      console.log(data);
      this.selCat = null;
      this.util.hide();
      localStorage.clear();
      this.navCtrl.navigateRoot(['']);
    }, error => {
      console.log(error);
      this.util.hide();
      this.util.apiErrorHandler(error);
    }).catch((error) => {
      console.log(error);
      this.util.hide();
      this.util.apiErrorHandler(error);
    });
  }

  isLoggedIn() {
    const uid = localStorage.getItem('token');
    if (uid && uid !== null && uid !== 'null') {
      return true;
    }
    return false;
  }

  onLogin() {
    this.router.navigate(['login'])
  }
}
