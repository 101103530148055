/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { OffersDetailsPageModule } from './pages/offers-details/offers-details.module';
import { AddressDetailPageModule } from './pages/address-detail/address-detail.module';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { StripePayPageModule } from './pages/stripe-pay/stripe-pay.module';
import { PaypalPayPageModule } from './pages/paypal-pay/paypal-pay.module';
import { AddStripeCardPageModule } from './pages/add-stripe-card/add-stripe-card.module';
import { TrackingInformationsPageModule } from './pages/tracking-informations/tracking-informations.module';
import { SelectCountryPageModule } from './pages/select-country/select-country.module';
import { ProductsReviewsPageModule } from './pages/products-reviews/products-reviews.module';
import { SortModalPageModule } from './pages/sort-modal/sort-modal.module';
import { FilterPageModule } from './pages/filter/filter.module';
import { environment } from '../environments/environment';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { VerifyPageModule } from './pages/verify/verify.module';
import { ComponentsModule } from './components/components.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RatingPageModule } from './pages/rating/rating.module';
import { ReturnOrderPageModule } from './pages/return-order/return-order.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    OffersDetailsPageModule,
    AddressDetailPageModule,
    StripePayPageModule,
    PaypalPayPageModule,
    AddStripeCardPageModule,
    TrackingInformationsPageModule,
    SelectCountryPageModule,
    ProductsReviewsPageModule,
    SortModalPageModule,
    FilterPageModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    VerifyPageModule,
    ComponentsModule,
    RatingPageModule,
    ReturnOrderPageModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [InAppBrowser, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule { }
