/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://ecomm.actweb.store/public/api/', // EXAMPLE https://api.xyz.com/public/api/
  imageUrl: 'https://ecomm.actweb.store/public/storage/images/', // https://api.xyz.com/public/storage/images/
  firebase: {
    apiKey: "AIzaSyBqtu4IBIWFqjxHbRDwoEKbxzcxhhXuKaA",
    authDomain: "act-demo-apps.firebaseapp.com",
    projectId: "act-demo-apps",
    storageBucket: "act-demo-apps.appspot.com",
    messagingSenderId: "949203149536",
    appId: "1:949203149536:web:9350b273691c4b1ea2a685"
  },
  eulaLink: 'https://www.google.com/',
  privacyLink: 'https://www.google.com/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
